import { Box, Card, CardHeader, Chip, Skeleton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSiteTemplate } from 'api/site';
import { useParams } from 'react-router-dom';
import { formatLocaleDate } from 'utils/dateFormat';
import { BackButton } from './BackButton';
import { linkHelper } from 'linkHelper';

export default function ViewSiteTemplate() {
  const { t, i18n } = useTranslation();
  const { templateId = '' } = useParams<{ templateId: string }>();
  const { data, isLoading } = useSiteTemplate(templateId);

  const themes = (data?.data.result?.config?.wordpress?.themes ?? []).map((item, index) => ({
    ...item,
    id: index,
  }));

  const plugins = (data?.data.result?.config?.wordpress?.plugins ?? []).map((item, index) => ({
    ...item,
    id: index,
  }));

  const items = [
    {
      id: t('template_name'),
      value: data?.data.result?.name ?? '-',
    },
    {
      id: t('notes'),
      value: data?.data.result?.notes ?? '-',
    },
    {
      id: t('created'),
      value: data?.data.result?.created_at
        ? formatLocaleDate({ locale: i18n.language, value: data?.data.result?.created_at })
        : '-',
    },
    {
      id: t('updated'),
      value: data?.data.result?.updated_at
        ? formatLocaleDate({ locale: i18n.language, value: data?.data.result?.updated_at })
        : '-',
    },
    {
      id: t('original_domain'),
      value: data?.data.result?.created_from?.domain ?? '-',
    },
    {
      id: t('wordpress_version'),
      value: data?.data.result?.config?.wordpress?.version ?? '-',
    },
  ];
  return (
    <Stack gap={4}>
      <BackButton to={linkHelper.newDesign.settings.siteTemplates.getLink()} />
      <Stack gap={6}>
        <Typography variant="h2" color="greys.900">
          {data?.data.result?.name ?? (
            <Skeleton variant="text" sx={{ lineHeight: '2.5rem' }} width={160} />
          )}
        </Typography>
        <Box
          sx={{
            display: 'grid',
            gap: 'var(--dashboard-gap)',
            gridTemplateColumns: {
              sm: '1fr',
              lg: '1fr 1fr',
            },
          }}
        >
          <Card sx={{ height: 'fit-content' }}>
            <CardHeader title={t('template_information')} />
            <Box
              sx={{
                display: 'grid',
                padding: 4,
                gap: 4,
                gridTemplateColumns: {
                  sm: '1fr',
                  lg: '1fr 1fr',
                },
              }}
            >
              {items.map(item => (
                <Stack>
                  <Typography variant="body2" color="greys.500">
                    {item.id}
                  </Typography>
                  <Typography variant="body2" color="greys.900">
                    {isLoading ? <Skeleton variant="text" width={160} /> : item.value}
                  </Typography>
                </Stack>
              ))}
            </Box>
          </Card>
          <Card>
            <CardHeader title={t('themes_and_plugins')} />
            <Box
              sx={{
                padding: 4,
              }}
            >
              <Stack gap={6}>
                <Box>
                  <Typography variant="body2" color="greys.500">
                    <Stack direction="row" gap={2}>
                      {t('themes_installed')}
                      <Chip variant="badge" color="secondary" label={themes.length ?? 0} />
                    </Stack>
                  </Typography>
                  <Stack gap={1}>
                    {themes.map(item => (
                      <Typography key={item.id} variant="body2" color="greys.900">
                        {item.name}
                      </Typography>
                    ))}
                  </Stack>
                </Box>
                <Box>
                  <Typography variant="body2" color="greys.500">
                    <Stack gap={2} direction="row">
                      {t('plugins_installed')}
                      <Chip variant="badge" color="secondary" label={plugins.length ?? 0} />
                    </Stack>
                  </Typography>
                  <Stack gap={1}>
                    {plugins.map(item => (
                      <Typography key={item.id} variant="body2" color="greys.900">
                        {item.name}
                      </Typography>
                    ))}
                  </Stack>
                </Box>
              </Stack>
            </Box>
          </Card>
        </Box>
      </Stack>
    </Stack>
  );
}
